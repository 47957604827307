import React from "react";
import Empty from "antd/es/empty";
import Descriptions from "antd/es/descriptions";

export default function SettlementRules({ rental, contract, rentalShare }) {
  return contract &&
    rental !== 0 &&
    (contract.owners_take > 0 ||
      contract.minimum_profit > 0 ||
      contract.vat_taxpayer ||
      contract.commission_toggle) ? (
    <div style={{ maxWidth: 750 }}>
      <br />
      <Descriptions
        title={`Zasady rozliczenia miesiąca dla apartamentu ${rental.name}`}
        layout="horizontal"
        column={1}
        bordered
      >
        {contract.owners_take > 0 && (
          <Descriptions.Item label="Procentowy udział w przychodzie netto z rezerwacji">
            {" "}
            {contract.owners_take * 100} %{" "}
          </Descriptions.Item>
        )}
        {contract.commission_toggle && (
          <Descriptions.Item label="Podział przychodu po odjęciu kosztu uzyskania rezerwacji">
            {" "}
            Tak{" "}
          </Descriptions.Item>
        )}
        {contract.minimum_profit > 0 && (
          <Descriptions.Item label="Minimalny czynsz">
            {" "}
            {contract.minimum_profit} PLN{" "}
          </Descriptions.Item>
        )}
        {rental.vat_taxpayer && (
          <Descriptions.Item label="Właściciel jest osobą prowadzącą działalność gospodarczą lub osobą prawną">
            {" "}
            Tak{" "}
          </Descriptions.Item>
        )}
        {rental.vat_taxpayer && rental.vat_rate > 0 && (
          <Descriptions.Item label="Zadeklarowana przez Właściciela stawka VAT">
            {" "}
            {rental.vat_rate * 100} %{" "}
          </Descriptions.Item>
        )}
        {rentalShare > 0 && rentalShare !== 1 && (
          <Descriptions.Item label="Procentowy udział Właściciela w apartamentcie">
            {" "}
            {rentalShare * 100} %{" "}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  ) : (
    <Empty
      className="empty-sign"
      description="Brak informacji o zasadach rozliczenia."
    />
  );
}
