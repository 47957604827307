import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Header from "./Header";

export function LayoutTopSpacer({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: "white",
        margin: 0,
        padding: 0,
        borderRadius: 10,
        marginTop: 50,
      }}
    >
      {children}
    </div>
  );
}

function Layout({
  isLoggedIn,
  filters,
  children,
  nav,
  renderLocaleSwitcher,
  title,
}) {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: 20,
      }}
    >
      <Header
        type={filters ? "filters" : "center"}
        isLoggedIn={isLoggedIn}
        nav={nav}
        renderLocaleSwitcher={renderLocaleSwitcher}
      />
      <div
        style={{
          overflowY: "hidden",
          overflowX: "auto",
        }}
      >
        {filters}
      </div>
      {children}
    </div>
  );
}

Layout.propTypes = {
  isLoggedIn: PropTypes.bool,
  filters: PropTypes.node,
};

Layout.defaultProps = {
  isLoggedIn: true,
  filters: null,
  title: "Portal dla właścicieli - Apartview",
};

export default Layout;
