import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "antd/es/button";
import { logOut } from "./Dashboard";
import { LocaleSwitcher } from "./IntlUtils";
import "./utils.css";

function Header({ type, isLoggedIn, nav, renderLocaleSwitcher }) {
  const leftFlex = type === "center" ? 1 : 0.05;
  const rightFlex = isLoggedIn || renderLocaleSwitcher ? 0.15 : 0;
  const midFlex = 1 - leftFlex - rightFlex;

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: leftFlex,
          alignItems: "center",
          justifyContent: type === "center" ? "center" : null,
        }}
      >
        <Link to="/">
          <img
            alt="Rent like home"
            src="/Apartview.png"
            style={{
              height: 60,
              width: 150,
            }}
          />
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flex: midFlex,
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {nav &&
          nav.map((item, index) => (
            <div
              className="detail-button div-button"
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                fontSize: 14,
                fontWeight: "bold",
                color: "#ffc72c",
              }}
              key={index}
            >
              {item}
            </div>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          flex: rightFlex,
          justifySelf: "flex-end",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {renderLocaleSwitcher && <LocaleSwitcher />}
        {isLoggedIn && (
          <Button
            style={{
              background: "#ffc72c",
              fontSize: 12,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            className="logout-button"
            type="primary"
            onClick={() => logOut(false)}
          >
            Wyloguj
          </Button>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  type: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
};

Header.defaultProps = {
  type: "center",
  children: null,
  renderLocaleSwitcher: false,
};

export default Header;
